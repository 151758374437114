import { Mark, Node } from "tiptap";
import { updateMark } from "tiptap-commands";
import { nodeIsActive } from "tiptap-utils";

export class Color extends Mark {
  get name() {
    return "color";
  }

  get schema() {
    return {
      inline: true,
      group: "inline",
      attrs: {
        value: {}
      },
      parseDOM: [
        {
          style: "color",
          getAttrs: (value) => value !== "" && { value }
        }
      ],
      toDOM: (node) => ["span", { style: `color: ${node.attrs.value};` }, 0]
    };
  }

  commands({ type }) {
    return (attrs) => updateMark(type, attrs);
  }
}

export class Icon extends Node {
  get name() {
    return "icon";
  }

  get defaultOptions() {
    return {
      size: "inherit"
    };
  }

  get schema() {
    return {
      inline: true,
      group: "inline",
      draggable: true,
      selectable: true,
      attrs: {
        name: {}
      },
      parseDOM: [
        {
          tag: "span",
          getAttrs: (dom) =>
            (dom.classList.contains("glyphicon") ||
              dom.classList.contains("fa")) && {
              name: dom.className
            }
        },
        {
          tag: "i",
          getAttrs: (dom) =>
            (dom.classList.contains("glyphicon") ||
              dom.classList.contains("fa")) && {
              name: dom.className
            }
        }
      ],
      toDOM: (node) => [
        "span",
        {
          class: node.attrs.name,
          style: `font-size: ${this.options.size};`
        }
      ]
    };
  }

  commands({ type }) {
    return (attrs) => (state, dispatch) => {
      const isActive = nodeIsActive(state, type);
      let transaction;

      if (isActive) {
        const position = state.selection.from;
        if (state.doc.nodeAt(position).hasMarkup(type, attrs)) {
          return false;
        }
        transaction = state.tr.setNodeMarkup(position, null, attrs);
      } else {
        const { selection } = state;
        const position = state.selection.$cursor
          ? selection.$cursor.pos
          : selection.$to.pos;
        const node = type.create(attrs);
        transaction = state.tr.insert(position, node);
      }

      dispatch(transaction);
      return true;
    };
  }
}
